$font-family-sans-serif: 'Poppins', sans-serif;

@import "~bootstrap-icons/font/bootstrap-icons";
@import "styles/fonts";
@import "styles/bootstrap";

:root {
  overflow: hidden;
}

body {
  background-color: #f5f5f5;
}

.container-fluid {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

form {
  label {
    font-weight: $font-weight-bold;
  }

  .form-check.font-weight-normal {
    label {
      font-weight: $font-weight-normal;
    }
  }
}

.booking-card {
  .booking-card-table {
    tr.line-through {
      td {
        text-decoration: line-through;
      }
    }

    td {
      padding-right: map-get($spacers, 2);
    }
  }
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}